import React from "react";
import styled from "styled-components";
import MR_hikaru from "../../../../images/MR_hikaru.png";
import MRFooter from "./MRFooter.js"

const Img = styled.img`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

export default class H01137 extends React.Component {
  render() {
    return (
      <section className="container">
        <Img
          src={MR_hikaru}
          alt="H01137_img"
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        />
        <MRFooter
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
          isOverflowHidden={this.props.isOverflowHidden}
          loader={() => this.props.loader()}
          firstLoader={() => this.props.firstLoader()}
          ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        />
      </section>
    );
  }
}
