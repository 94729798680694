import React from 'react';
import { Redirect } from 'react-router-dom';

export default class Top extends React.Component {
  render() {
    return (
      <Redirect to={'/documents/keywords'} />
    )
  }
}
