import React from 'react';

class Page404 extends React.Component {
  render() {
    return (
      <div>
        <h3>お探しのページは見つかりませんでした</h3>
      </div>
    )
  }
}

export default Page404;
