import React from "react";
import styled from "styled-components";

const Div = styled.div`
  display: ${props => (props.isLoading ? "inline-block" : "none")};
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.5;
    /* filter: blur(5px); */
    width: ${props =>
    props.isLoadingContainerBackgroundcolor2 ? "100%" : "0%"};
    height: 100%;
    content: "";
    transition: all 300ms 0s ease;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.5;
    width: ${props =>
    props.isLoadingContainerBackgroundcolor ? "100%" : "0%"};
    height: 100%;
    content: "";
    transition: all 300ms 0s ease;
  }
`;

const P = styled.p`
  /* font-size: 0.75em; */
  display: block;
  width: 180px;
  height: 100px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: ${props =>
    props.isLoadingContainerBackgroundcolor ? "blur(0px)" : "blur(10px)"};
  transition: all 500ms 0s ease-out;
  opacity: 0.5;
`;

//TODO ローダータイトルのPC版サイズ調整

const Img = styled.img`
  display: block;
  filter: ${props =>
    props.isLoadingContainerBackgroundcolor ? "blur(0px)" : "blur(10px)"};
  transition: all 500ms 0s ease;
  width: 100%;
`;

const PLoderTitle = styled.p`
  font-size: 50px;
  font-weight: 300;
`;

const PLoderSubtitle = styled.p`
  font-size: 18px;
  position: absolute;
  top: 12;
  left: 0;
  right: 0;
  bottom: 0;
  text-align:right;
`;

export default class Loader extends React.Component {
  render() {
    return (
      <Div
        isLoading={this.props.isLoading}
        isLoadingContainerBackgroundcolor={
          this.props.isLoadingContainerBackgroundcolor
        }
        isLoadingContainerBackgroundcolor2={
          this.props.isLoadingContainerBackgroundcolor2
        }
      >
        <P
          isLoadingContainerBackgroundcolor={
            this.props.isLoadingContainerBackgroundcolor
          }
        >
          <PLoderTitle>Packing</PLoderTitle>
          <PLoderSubtitle>-field hospital</PLoderSubtitle>
          {/* <Img
            src={packing_livingroom}
            alt="packing_livingroom"
            isLoading={this.props.isLoading}
            isLoadingContainerBackgroundcolor={
              this.props.isLoadingContainerBackgroundcolor
            }
          /> */}
        </P>
      </Div>
    );
  }
}
