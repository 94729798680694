import React from "react";
import { render } from "react-dom";
import styled from 'styled-components';
import { withRouter } from "react-router-dom";


const DivCon = styled.div`
  margin: 0 auto 20px;
  display: inline;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 8px;
`;

const P = styled.p`
  text-align: justify;
  position: relative;
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

class MRFooter extends React.Component {
  ChangeOverflowHidden = () => {
    return new Promise(resolve => {
      resolve(this.props.ChangeOverflowHidden());
    });
  };
  firstLoader = () => {
    return new Promise(resolve => {
      resolve(this.props.firstLoader());
    });
  };
  mainLoader = () => {
    return new Promise(resolve => {
      resolve(this.props.loader());
    });
  };
  pushHistoryMedicalRecords = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/medicalrecords"));
      }, 650);
    });
  };
  handleToMedicalRecords = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryMedicalRecords)
      .then(this.mainLoader);
  };
  render() {
    return (
      <DivCon
        isLoadingTextBackgroundcolor={
          this.props.isLoadingTextBackgroundcolor
        }
        onClick={() => {
          this.handleToMedicalRecords();
        }}
      >
        <P
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <br />
          ←
          </P>
      </DivCon>
    )
  }
}

export default withRouter(MRFooter);
