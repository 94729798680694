// TODO　メニューのレイアウト修正

// TODO メニューコンポーネント切り出す
// TODO　PCメニューもspと同じようなボタン+ドキュメントだけ他の項目に切り出す


import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import packing_livingroom from "../../images/packing_livingroom.svg";

class Header extends React.Component {
  ChangeOverflowHidden = () => {
    return new Promise(resolve => {
      resolve(this.props.ChangeOverflowHidden());
    });
  };
  firstLoader = () => {
    return new Promise(resolve => {
      resolve(this.props.firstLoader());
    });
  };
  pushHistoryAbout = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/about"));
      }, 650);
    });
  };
  pushHistoryMedicalRecords = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/medicalrecords"));
      }, 650);
    });
  };
  pushHistoryKeywords = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/keywords"));
      }, 650);
    });
  };
  pushHistoryPerformance = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/performance"));
      }, 650);
    });
  };
  pushHistoryMemos = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/memos"));
      }, 650);
    });
  };
  pushHistoryAfterwords = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/afterwords"));
      }, 650);
    });
  };
  mainLoader = () => {
    return new Promise(resolve => {
      resolve(this.props.loader());
    });
  };
  handleToAbout = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryAbout)
      .then(this.mainLoader);
  };
  handleToMedicalRecords = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryMedicalRecords)
      .then(this.mainLoader);
  };
  handleToKeywords = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryKeywords)
      .then(this.mainLoader);
  };
  handleToPerformance = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryPerformance)
      .then(this.mainLoader);
  };
  handleToMemos = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryMemos)
      .then(this.mainLoader);
  };
  handleToAfterwords = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryAfterwords)
      .then(this.mainLoader);
  };
  render() {
    return (
      <HeaderComp>
        <Div>
          <MenuComp isMenuOpen={this.props.isMenuOpen}>
            <MenuUl isMenuOpen={this.props.isMenuOpen}>
              <MenuLi>
                <DocumentsParent
                  onClick={() => {
                    this.handleToKeywords();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  Documents
              </DocumentsParent>
              </MenuLi>
              <MenuLi>
                <DocumentsChil
                  onClick={() => {
                    this.handleToMedicalRecords();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  "Medical Records"
              </DocumentsChil>
              </MenuLi>
              <MenuLi>
                <DocumentsChil
                  onClick={() => {
                    this.handleToKeywords();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  Keywords
              </DocumentsChil>
              </MenuLi>
              <MenuLi>
                <DocumentsChil
                  onClick={() => {
                    this.handleToPerformance();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  Performance
              </DocumentsChil>
              </MenuLi>
              <MenuLi>
                <DocumentsChil
                  onClick={() => {
                    this.handleToMemos();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  Memos
              </DocumentsChil>
              </MenuLi>
              <MenuLi>
                <MenuH3About
                  onClick={() => {
                    this.handleToAbout();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  About
              </MenuH3About>
              </MenuLi>
              <MenuLi>
                <a href="https://forms.gle/d6bjKkAA4rFv2SZD9" target="_blank">
                  <MenuH3About
                    isMenuOpen={this.props.isMenuOpen}
                  >
                    Form
              </MenuH3About></a>
                <PNews>
                  ご意見・ご感想をお寄せください。
                </PNews>
              </MenuLi>
              <MenuLi>
                <PNews>
                  ※随時コンテンツ更新予定
                </PNews>
              </MenuLi>
              {/* <MenuLi>
                <MenuH3Afterwords
                  onClick={() => {
                    this.handleToAfterwords();
                    this.props.MenuAction();
                  }}
                  isMenuOpen={this.props.isMenuOpen}
                >
                  Afterwords
              </MenuH3Afterwords>
              </MenuLi> */}
            </MenuUl>
          </MenuComp>
        </Div>
        <Div>
          <a href="/">
            <LogoImg src={packing_livingroom} alt={packing_livingroom}></LogoImg>
          </a>
          <MenuBottun onClick={this.props.MenuAction}>
            <MenuBottunLine1 />
            <MenuBottunLine2 />
            <MenuBottunLine3 />
          </MenuBottun>
        </Div>
      </HeaderComp>
    );
  }
}

const HeaderComp = styled.header`
  width: 100vw;
  max-width: 984px;
  position: fixed;
  top: 32px;
  z-index: 3;
  @media(max-width:768px){
    top: 0;
  }
`;

const Div = styled.div`
  position: relative;
  z-index: 2;
`;


//// logo ////////////////////////////////////////

const LogoImg = styled.img`
  width: 25%;
  max-width: 150px;
  background-color: rgba(238, 238, 238, 0.5);
  padding: 0.2rem;
  margin: 16px 0 0 6px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

//// Menu Bottun ////////////////////////////////////////

// 独自でcssを当てて、loaderと同じような動きにする。
const MenuBottun = styled.div`
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 24px;
  width: 40px;
  height: 34px;
  padding: 0.2rem;
  background-color: rgba(238, 238, 238, 0.5);
  &:hover {
    cursor: pointer;
  }
`;

const MenuBottunLine1 = styled.hr`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 2px;
  background-color: black;
  border: none;
`;

const MenuBottunLine2 = styled.hr`
  position: absolute;
  top: 15px;
  left: 5px;
  width: 30px;
  height: 2px;
  background-color: black;
  border: none;
`;

const MenuBottunLine3 = styled.hr`
  position: absolute;
  top: 25px;
  left: 5px;
  width: 30px;
  height: 2px;
  background-color: black;
  border: none;
`;

//// Menu Contents ////////////////////////////////////////

const Ul = styled.ul`
  display: none;
  display: block;
  padding-right: 48px;
  width: 100vw;
  max-width: 984px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
`;

const Li = styled.li`
  margin-left: 40px;
`;

const H3 = styled.h3`
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const IconH3 = styled.h3`
  font-size: 1.5rem;
  text-align: right;
`;

const A = styled.a`
  background-color: rgba(255, 255, 255, 0.5);
`;


const MenuComp = styled.div`
  position: absolute;
  top: ${props => (props.isMenuOpen ? "0" : "-500")};
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 300ms 0s ease;
`;

const MenuUl = styled.ul`
  display: block;
  position: absolute;
  top: ${props => (props.isMenuOpen ? "0" : "-550px")};
  left: 0;
  right: 0;
  background-color: rgba(238, 238, 238, 0.8);
  padding: 120px 0 40px 20px;
  margin: 0 auto;
  transition: all 300ms 0s ease;
  opacity: ${props => (props.isMenuOpen ? "1" : "0")};
`;

const MenuLi = styled.li`
  list-style: none;
`;

const MenuIconH3 = styled.h3`
  font-size: 1.5rem;
`;

const MenuH3Afterwords = styled.h3`
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  transition: all 300ms 0s ease;
  margin: 5px auto auto;
  font-size: 40px;
  pointer-events: ${props => (props.isMenuOpen ? "auto" : "none")};
  font-weight: 400;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const MenuH3About = styled.h3`
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  transition: all 300ms 0s ease;
  margin: 40px auto auto;
  font-size: 40px;
  pointer-events: ${props => (props.isMenuOpen ? "auto" : "none")};
  font-weight: 400;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const DocumentsParent = styled.h3`
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  margin: 0 auto;
  transition: all 300ms 0s ease;
  font-size: 50px;
  pointer-events: ${props => (props.isMenuOpen ? "auto" : "none")};
  font-weight: 400;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const DocumentsChil = styled.h3`
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  margin:10px auto auto 20px;
  transition: all 300ms 0s ease;
  font-size: 30px;
  pointer-events: ${props => (props.isMenuOpen ? "auto" : "none")};
  font-weight: 200;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const MenuA = styled.a`
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const PNews = styled.h3`
  display: inline-block;
  margin:30px auto auto 20px;
  transition: all 300ms 0s ease;
  font-size: 14px;
  pointer-events: ${props => (props.isMenuOpen ? "auto" : "none")};
  font-weight: 200;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export default withRouter(Header);

// レスポンシブの方針
// PC → インナー幅(1000px)まで
// タブレット → インナーの幅(1000px)からiPad（768px）まで
// スマホ → iPadより小さい（767px）からiPhone SE（320px）まで
