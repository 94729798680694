import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fh_img from "../../images/about_fieldhospital.JPG";
import profile_img from "../../images/about_profile.JPG";
import portfolio1_img from "../../images/about_portfolio1.png";
import portfolio2_img from "../../images/about_portfolio2.png";
import portfolio3_img from "../../images/about_portfolio3.png";
import portfolio4_img from "../../images/about_portfolio4.JPG";
import packing_img from "../../images/keywords_packing.JPG";



const Img = styled.img`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const DivPhoto = styled.div`
  margin: 0 auto 20px;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  padding-bottom: 20px;
`;

const PCaption = styled.p`
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  font-size: 0.8rem;
  margin: 4px auto 0;
`;

const H3 = styled.h3`
  display: inline;
  position: relative;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const H2 = styled.h2`
  position: relative;
  display: inline;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  @media (max-width: 450px) {
    display: inline-block;
    margin-block-end: 0.3em;
  }
`;

const P = styled.p`
  position: relative;
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const BalancedBr = styled.br`
  display: none;
  @media (max-width: 450px) {
    display: block;
  }
`;

const DivName = styled.div`
  margin: 30px 0;
  position: relative;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
`;

const DivPortfolio = styled.div`
  margin: 0 auto 6px;
  display: inline;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 18px 18px 18px;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
`;

const DivContact = styled.div`
  margin: 16px 0;
  `;

const DivHr = styled.div`
  margin: 32px 0;
  `;

const Hr = styled.hr`
  margin-left: 0;
  width: 120px;
  size: 1;
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 300ms 0s ease-in-out;
  `;

const PEndSpace = styled.p`
  display: block;
  margin-block-end: 0.6em;
  position: relative;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  `;

const Caption = styled.p`
  font-size: 0.8em;
  `

const Link = styled.a`
  background-color: rgba(255, 255, 255, 0.8);
`

export default class About extends React.Component {
  render() {
    return (
      <section className="container">
        <Img
          src={fh_img}
          alt="fh_img"
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        />
        <DivName>
          <H2
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            "Packing - field hospital"
          </H2>
          <BalancedBr />
          <H3
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
            isOverflowHidden={this.props.isOverflowHidden}
          >
            Yuuki Nakaya
          </H3>
        </DivName>
        <DivName>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <PEndSpace>
              東京藝術大学卒業・修了作品展
              <br />
              @東京都美術館<br />
            </PEndSpace>
            2020.1.28-2.2<br />
          </P>
          <DivContact>
            <P
              isLoadingTextBackgroundcolor={
                this.props.isLoadingTextBackgroundcolor
              }
            >
              <h4><PEndSpace>Performance</PEndSpace></h4>
              各日14時〜<br />
              @"Packing - field hospital"作品付近<br />
            </P>
            <PEndSpace
              isLoadingTextBackgroundcolor={
                this.props.isLoadingTextBackgroundcolor
              }
            >
              出演：深澤しほ<br />
              テキスト・演出：中谷優希<br />
              協力：PUGMENT、志賀耕太<br />
            </PEndSpace>
          </DivContact>
        </DivName>
        <DivHr>
          <Hr
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          />
        </DivHr>
        <Img
          src={profile_img}
          alt="profile_img"
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        />
        <DivName>
          <H2
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          >中谷優希</H2>
        </DivName>
        <a href="https://drive.google.com/file/d/17XU_rcO_I4_ZQugpAZHCSFvHAZWHXbYq/view" target="_blank">
          <DivPortfolio
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          >
            ポートフォリオ
          </DivPortfolio>
        </a>
        <DivName>
          <P
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          >
            <PEndSpace>
              <h3>1996</h3>
              北海道生まれ<br /><br />
              <h3>2016</h3>
              東京藝術大学美術学部先端表現学科 入学<br /><br />
              <h3>2017</h3>
              「Rêver 2074」出展<br />
              <Caption>（主催:COMITÉ COLBERT コルベール委員会、東京藝術大学）</Caption><br />
              <h3>2019</h3>
              <Link href="http://kyoto-research.com/kameoka2018" target="_blank">
                「京都:Re-Search 2018 in 亀岡」
              </Link>
              参加<br />
              <Caption>（主催：京都:Re-Search 実行委員会）</Caption><br />
              <h3>2020</h3>
              <Link href="http://kyoto-research.com/daikyoto-kameoka2019" target="_blank">
                「大京都 2019 in 亀岡」
              </Link>
              参加<br />
              <Caption>（主催：京都:Re-Search 実行委員会、会場：京都府亀岡市内、ディレクション：目）※COVID‑19のため中止</Caption><br />
              「第68回 東京藝術大学卒業・修了作品展」
              出展<br />
              <Caption>（主催：東京藝術大学、会場：東京都美術館）</Caption><br />
              東京藝術大学美術学部先端表現学科 卒業<br /><br />
              <Link href="http://haps-kyoto.com/allnighthaps-2020_2/" target="_blank">
                ALLNIGHT HAPS 2020「Probable Cause」
              </Link>
              出展<br />
              <Caption>（主催：一般社団法人HAPS、会場：HAPS 東山アーティスツ・プレイスメント・サービス、企画：小松千倫）</Caption><br />
              <h3>2021</h3>
              「大京都 2021 in 亀岡 移動する有体 構想報告」展出展<br />
              <Caption>（主催：京都:Re-Search 実行委員会、会場：京都府亀岡市内、ディレクション：目）</Caption><br />
              <Link href="https://4us.geidai.ac.jp/" target="_blank">
                「居場所はどこにある？」
              </Link>
              出展<br />
              <Caption>（主催：「居場所はどこにある？」実行委員会、会場：東京藝術大学大学美術館陳列館、キュレーター：荒木夏実）</Caption><br />
              <Link href="https://www.pref.gunma.jp/contents/100209143.pdf" target="_blank">
                「群馬青年ビエンナーレ2021」
              </Link>
              奨励賞受賞<br />
              <Caption>（主催：群馬県立近代美術館、上毛新聞社）</Caption><br />
              <Link href="https://www.stilllive.org/2021" target="_blank">
                「Stilllive: Performance Art Summit Tokyo 2021-2022──衛生･変身･歓待」
              </Link>
              参加<br />
              <Caption>（主催：Stilllive (スティルライブ)、会場：ゲーテ･インスティトゥート東京、企画・アーティスティック･ディレクター：小林勇輝)</Caption><br />
              お布団 CCS/SC 1st Expansion『夜を治める者《ナイトドミナント》』ワーク・イン・プログレス 舞台美術
              <Caption>（主催：お布団、会場：アトリエ春風舎）</Caption><br /><br />
              <h3>2022</h3>
              お布団 CCS/SC 1st Expansion『夜を治める者《ナイトドミナント》』舞台美術<br />
              <Caption>（主催：お布団、会場：こまばアゴラ劇場）</Caption><br />
              <Link href="https://www.stilllive.org/activity" target="_blank">
                「Stilllive Performance Art Summit Tokyo 2022──Tribute of Performance Anthology」
              </Link>
              参加<br />
              <Caption>（主催：Stilllive (スティルライブ)、会場：ゲーテ･インスティトゥート東京、企画・アーティスティック･ディレクター：小林勇輝)</Caption>
              <br /><br />
            </PEndSpace>
            <h5><PEndSpace>
              {/* <a
                href="https://twitter.com/080709Ims"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
                &emsp;@080709Ims
              </a><br /> */}
              <FontAwesomeIcon icon="envelope" />
              &emsp;yuuki.080709.ny@gmail.com
            </PEndSpace>
            </h5>
            <DivPhoto
              isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
            >
              <Img
                src={portfolio4_img}
                alt="portfolio4_img"
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
              <br />
              <Img
                src={packing_img}
                alt="portfolio5_img"
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
              <PCaption>
                『Packing - firefighter』
              </PCaption>
            </DivPhoto>
            <DivPhoto
              isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
            >
              <Img
                src={portfolio1_img}
                alt="portfolio1_img"
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
              <PCaption>
                『ICONOCLASM - scapegoat』
              </PCaption>
            </DivPhoto>
            <DivPhoto
              isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
            >
              <Img
                src={portfolio2_img}
                alt="portfolio2_img"
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
              <PCaption>
                『身体所有の模型』
              </PCaption>
            </DivPhoto>
            <DivPhoto
              isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
            >
              <Img
                src={portfolio3_img}
                alt="portfolio3_img"
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
              <PCaption>
                『LATEX FRUIT SYNDROME』
              </PCaption>
            </DivPhoto>
          </P>
        </DivName>
      </section>
    );
  }
}
