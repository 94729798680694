import React from 'react';
import styled from 'styled-components';
import p0001_img from "../../../images/performance_0001.JPG";
import p0002_img from "../../../images/performance_0002.JPG";
import p0003_img from "../../../images/performance_0003.jpg";
import p0004_img from "../../../images/performance_0004.jpg";
import p0005_img from "../../../images/performance_0005.JPG";
import p0006_img from "../../../images/performance_0006.JPG";
import p0007_img from "../../../images/performance_0007.JPG";

const P = styled.p`
  text-align: justify;
  position: relative;
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const DivPhoto = styled.div`
  margin: 0 auto 20px;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  padding-bottom: 20px;
`;

const DivCon = styled.div`
  margin: 0 auto 20px;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 18px 8px 0;
`;

const Img = styled.img`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const Iframe = styled.iframe`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

export default class Performance extends React.Component {
  render() {
    return (
      <section className="container">
        <h2>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            Performance
          </P>
        </h2>
        <h5>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            展示期間中、毎日行われるパフォーマンスの資料を掲載しています。
          </P>
        </h5>
        <DivPhoto
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Iframe src="https://www.youtube.com/embed/kviBeEUodbo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Iframe>
        </DivPhoto>
        <a href="https://drive.google.com/open?id=12aZHxW5kZJd_vT_jtWN21f-rdom_QhxT" target="_blank">
          <DivCon
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <P
              isLoadingTextBackgroundcolor={
                this.props.isLoadingTextBackgroundcolor
              }
            >"Packing - field hospital"<br />パフォーマンス上演台本
          </P>
          </DivCon>
        </a>
        <DivPhoto>
          <Img
            src={p0001_img}
            alt="p0001_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
        <DivPhoto>
          <Img
            src={p0002_img}
            alt="p0002_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
        <DivPhoto>
          <Img
            src={p0003_img}
            alt="p0003_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
        <DivPhoto>
          <Img
            src={p0004_img}
            alt="p0004_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
        <DivPhoto>
          <Img
            src={p0005_img}
            alt="p0005_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
        <DivPhoto>
          <Img
            src={p0006_img}
            alt="p0006_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
        <DivPhoto>
          <Img
            src={p0007_img}
            alt="p0007_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
        </DivPhoto>
      </section>
    );
  }
}
