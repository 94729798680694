import React from "react";
import Top from "./Top.js";
import MedicalRecords from "./documents/MedicalRecords.js";
import Keywords from "./documents/Keywords.js";
import Memos from "./documents/Memos.js";
import Performance from "./documents/Performance.js";
import Afterwords from "./documents/Afterwords.js";
import About from "./About.js";
import Page404 from "../errors/Page404.js";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import S78005 from "./documents/MedicalRecords/S78005.js";
import N12060 from "./documents/MedicalRecords/N12060.js";
import K86124 from "./documents/MedicalRecords/K86124.js";
import H01137 from "./documents/MedicalRecords/H01137.js";
import F25144 from "./documents/MedicalRecords/F25144.js";


const MenuFilterDiv = styled.div`
  display: ${props => (props.isMenuOpen ? "display" : "none")};
  background-color: transparent;
  filter: blur(4px);
`;

const Div = styled.div`
  display: ${props => (props.isLoading ? "none" : "block")};
  position: relative;
  filter: ${props => (props.isMenuOpen ? "blur(2px)" : "none")};
`;

export default class Content extends React.Component {
  render() {
    return (
      <Div
        isLoading={this.props.isLoading}
      >
        <Switch>
          <Route exact path={"/"} component={Top} />
          <Route
            exact
            path={"/documents/medicalrecords"}
            render={props => (
              <MedicalRecords
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
                loader={() => this.props.loader()}
                firstLoader={() => this.props.firstLoader()}
                ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
              />
            )}
          />
          <Route
            exact
            path={"/documents/medicalrecords/S-78005"}
            render={props => (
              <S78005
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
                loader={() => this.props.loader()}
                firstLoader={() => this.props.firstLoader()}
                ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
            )}
          />
          <Route
            exact
            path={"/documents/medicalrecords/N-12060"}
            render={props => (
              <N12060
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
                loader={() => this.props.loader()}
                firstLoader={() => this.props.firstLoader()}
                ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
            )}
          />
          <Route
            exact
            path={"/documents/medicalrecords/K-86124"}
            render={props => (
              <K86124
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
                loader={() => this.props.loader()}
                firstLoader={() => this.props.firstLoader()}
                ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
            )}
          />
          <Route
            exact
            path={"/documents/medicalrecords/H-01137"}
            render={props => (
              <H01137
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
                loader={() => this.props.loader()}
                firstLoader={() => this.props.firstLoader()}
                ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
            )}
          />
          <Route
            exact
            path={"/documents/medicalrecords/F-25144"}
            render={props => (
              <F25144
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
                loader={() => this.props.loader()}
                firstLoader={() => this.props.firstLoader()}
                ChangeOverflowHidden={() => this.props.ChangeOverflowHidden()}
                isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
              />
            )}
          />
          <Route
            exact
            path={"/documents/keywords"}
            render={props => (
              <Keywords
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
              />
            )}
          />
          <Route
            exact
            path={"/documents/memos"}
            render={props => (
              <Memos
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
              />
            )}
          />
          <Route
            exact
            path={"/documents/performance"}
            render={props => (
              <Performance
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
              />
            )}
          />
          <Route
            exact
            path={"/documents/afterwords"}
            render={props => (
              <Afterwords
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
              />
            )}
          />
          <Route
            exact
            path={"/about"}
            render={props => (
              <About
                isLoadingTextBackgroundcolor={
                  this.props.isLoadingTextBackgroundcolor
                }
                isOverflowHidden={this.props.isOverflowHidden}
              />
            )}
          />
          <Route exact component={Page404} />
        </Switch>
      </Div>
    );
  }
}
