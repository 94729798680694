import React from 'react';
import styled from "styled-components";
import note1_img from "../../../images/memos_note1.jpg";
import note2_img from "../../../images/memos_note2.jpg";
import note3_img from "../../../images/memos_note3.jpg";
import note4_img from "../../../images/memos_note4.jpg";
import note5_img from "../../../images/memos_note5.jpg";
import note6_img from "../../../images/memos_note6.jpg";
import note7_img from "../../../images/memos_note7.jpg";
import note8_img from "../../../images/memos_note8.jpg";
import note9_img from "../../../images/memos_note9.jpg";
import note10_img from "../../../images/memos_note10.jpg";
import note11_img from "../../../images/memos_note11.jpg";
import note12_img from "../../../images/memos_note12.jpg";
import note13_img from "../../../images/memos_note13.jpg";

const Img = styled.img`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const DivPhoto = styled.div`
  margin: 0 auto 20px;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  padding-bottom: 20px;
`;

const PCaption = styled.p`
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  font-size: 0.8rem;
  margin: 4px auto 0;
`;

const H3 = styled.h3`
  display: inline;
  position: relative;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const H2 = styled.h2`
  position: relative;
  display: inline;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  @media (max-width: 450px) {
    display: inline-block;
    margin-block-end: 0.3em;
  }
`;

const P = styled.p`
  position: relative;
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "blur(0px)"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;


export default class Memos extends React.Component {
  render() {
    return (
      <section className="container">
        <h2>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            Memos
          </P>
        </h2>
        <h5>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            作品に関連する中谷のノートやメモ、ツイートなどを掲載しています。キャプションはアーカイブ担当の杉浦が書きました。
          </P>
        </h5>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note1_img}
            alt="note1_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <br />
          <Img
            src={note2_img}
            alt="note2_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            「Field Hospital」内に設置された「創傷被覆材」のスケッチ。
          </PCaption>
        </DivPhoto>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note3_img}
            alt="note3_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <br />
          <Img
            src={note4_img}
            alt="note4_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            パフォーマンスの舞台美術として使用される予定だった図式絵画（ダイアグラム）のスケッチ。
          </PCaption>
        </DivPhoto>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note5_img}
            alt="note5_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            パフォーマンスについてのメモ。フローレンス・ナイチンゲールを元にパフォーマンスが制作されたことがわかる。
          </PCaption>
        </DivPhoto>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note6_img}
            alt="note6_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <br />
          <Img
            src={note7_img}
            alt="note7_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            インスタレーションについてのメモ。中谷の思考の動きが見える。
          </PCaption>
        </DivPhoto>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note8_img}
            alt="note8_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <br />
          <Img
            src={note11_img}
            alt="note11_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            作品にまつわる概念のマッピング。荒川修作、三上晴子の影響がうかがえる。
          </PCaption>
        </DivPhoto>        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note9_img}
            alt="note9_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <br />
          <Img
            src={note10_img}
            alt="note10_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            「患者をつくるワークショップ」で使用された図式絵画（ダイアグラム）。
          </PCaption>
        </DivPhoto>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note12_img}
            alt="note12_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            「Packing」『Einstein on the Beach』についてのメモ。
          </PCaption>
        </DivPhoto>
        <DivPhoto
          isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
        >
          <Img
            src={note13_img}
            alt="note13_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption>
            作品プランについてのメモ。青葉市子 コーネリアス『外は戦場だよ』は作品制作時、中谷がよく流していた。
          </PCaption>
        </DivPhoto>
      </section>
    )
  }
}
