import React from "react";
import styled from "styled-components";

const FooterComp = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: 986px;
  padding: 0 16px 0;
`;

const Div = styled.div`
  position: relative;
`;

const P = styled.p`
  font-size: 0.9em;
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: absolute;
  right: 8px;
  bottom: 0;
`;

export default class Footer extends React.Component {
  render() {
    return (
      <FooterComp>
        <Div>
          <P>© 2020 Packing-field hospital</P>
        </Div>
      </FooterComp>
    );
  }
}
