import React from 'react';
import styled from "styled-components";
import packing_img from "../../../images/keywords_packing.JPG";
import 次元の射影_img from "../../../images/keywords_次元の射影.jpg";
import Nightingale_img from "../../../images/keywords_Nightingale.JPG";
import nの円_img from "../../../images/keywords_nの円.jpg";
import pugment_img from "../../../images/keywords_pugment.jpg";
import 内面化_img from "../../../images/keywords_内面化.jpg";
import 当事者性_img from "../../../images/keywords_当事者性.jpg";
import einstein_img from "../../../images/keywords_einstein.jpg";

const P = styled.p`
  text-align: justify;
  position: relative;
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const DivWords = styled.div`
  margin: 0 auto 20px;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  padding-bottom: 20px;
`;

const Hr = styled.hr`
  margin: 0 auto 30px 0;
  width: 120px;
  size: 1;
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const Img = styled.img`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const PCaption = styled.p`
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  font-size: 0.8rem;
  margin: 4px auto 0;
`;

export default class Keywords extends React.Component {
  render() {
    return (
      <section className="container">
        <h2>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            Keywords
          </P>
        </h2>
        <h5>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            ”Packing - field hospital”をより多くの視点から鑑賞できるよう、作品に関連するキーワードを掲載しています。テキストは中谷に加え、アーカイブ担当の杉浦が書いています。
          </P>
        </h5>
        <Hr
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        />
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>Packing</h3>
            人は生身では宇宙空間で生きることができません。しかし宇宙服に包まれることによって、宇宙に存在することができます。<br />
            &ensp;&ensp;宇宙服のようなこの効果を「包むこと」から「Packing」と呼んでいます。「Packing」とは「そのままの状態ではある場所に存在できないものを、そこに存在させるためのツール」です。「Packing」＝存在するための方法。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>存在できない／居ることができない</h3>
            日常生活にも、宇宙と同様に存在できない場所があります。例えば学校、職場、何かのコミュニティ、初めて行った場所、慣れないところ、苦手な人がいる空間……。<br />
            &ensp;&ensp;ある場所は時と場合によって、特定の個人が存在できない場所になります。「ここに居たい、ここに居なきゃいけない、けど居られない。」誰にでも起こりうるこのような状況において、それでもそこに存在するための方法を、私は「Packing（包むこと）」から考えています。(N)<br />
            <br />
            “Just 'cause you feel it / Doesn't mean it's there”<br />
            Radiohead（2003） “There, There” Hail To The Thief&ensp;(S)
          </P>
        </DivWords>
        <DivWords>
          <Img
            src={packing_img}
            alt="packing_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            「Packing」をテーマに制作された過去作品『Packing - firefighter』
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>病気の相対性</h3>
            病気とは、その基準のひとつに「日常生活/社会生活に支障が出るかどうか」があります。つまり病気とは相対的な状態です。病気の相対性と同様に、存在できる/できないの感覚も相対的なものです。（N）
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>Field Hospital</h3>
            野戦病院は英語で「Field Hospital」と言います。<br />&ensp;&ensp;「Field」は「野戦」の他に「領域」や「場」という意味も併せ持っています。わたしはこの「Field」を「存在できない場所/領域」と捉え、ここに存在できない人のための「Field Hospital」をつくりました。（N)<br />
            <br />
            サッカー用語のミッドフィルダー、『新世紀エヴァンゲリオン』の「ATフィールド」、フィールド・ワーク、日常会話で聞く「フィールドが違う」という言葉などを、中谷から作品の話を聞く時に頭に思い浮かべます。（S）
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>Florence Nightingale</h3>
            1853年のクリミア戦争。後に野戦病院のはじまりとなる病院で従軍していたのがフローレンス・ナイチンゲールです。彼女は「白衣の天使」という言葉の先駆けとなった存在です。しかし「天使とは美しい花をまき散らす者ではなく、苦悩する者のために戦う者である」と彼女は発言しています。わたしはナイチンゲールのこのスタンスをもって、存在できず苦悩する者と共に「存在するための方法」について考えています。(N)
          </P>
        </DivWords>
        <DivWords>
          <Img
            src={Nightingale_img}
            alt="Nightingale_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            本作ではナイチンゲールをモチーフにしたパフォーマンスを上演します
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>巡回</h3>
            ナイチンゲールには様々な逸話が残されており、そのうちの１つに夜回りの話しがあります。ナイチンゲールは夜回りを欠かさなかったため「ランプの貴婦人」「光を掲げる貴婦人」とも呼ばれています。この逸話を元に、小さなプロジェクターを巡回の火に見立て、巡回についてのパフォーマンスを行います。<br />
            &ensp;&ensp;一言に巡回とは、他者（ある事柄における当事者）の可能性を発見するために、当事者ではない者ができることの１つだとわたしは考えています。例えば、わたしが止まっていたなら、あなたの後ろ側は想像するしかない。ですが、あなたの周りをぐるっと歩き回れたのなら、もしかしたら、わたしはあなたの後ろ側に尻尾を見つけるかもしれないし、背中についてるドアを発見できるかもしれないのです。（N）
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>次元の射影</h3>
            マルセル・デュシャンの作品『彼女の独身者たちによって裸にされた花嫁、さえも』（通称 大ガラス）で表現されているように、「三次元のオブジェは全て四次元の投影だ」とデュシャンは言いました。<br />
            &ensp;&ensp;芸術家/建築家の荒川修作をニューヨークで出迎えたのが、デュシャンだったそうです。『Living room』をはじめ、荒川修作はデュシャンに影響を受けた作品を数多く残しています。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Img
            src={次元の射影_img}
            alt="次元の射影_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            本作のパフォーマンスで使用される予定だった舞台美術のスケッチ①
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>死なない</h3>
            「Man is mortal（人は死すべき存在である）」という誰もが逃れられない「Destiny（天命）」を「Reverse（反転）」させることを実践していた芸術家/建築家 荒川修作の「死なない（To not to die）」について、わたしは数年前から心奪われています。<br />
            &ensp;&ensp;物心ついた頃から、死にとてつもない恐怖を感じているわたしは、荒川の「死なない」を円と次元を用いて解釈しようとしました。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>2nの円</h3>
            ※次元をnと表記します。<br />
            &ensp;&ensp;平面上（2n）にふたつの円があります。これを立体（3n）にしたとき、先の円を底面とした円柱になります。もう１つは円錐になります。そのほかにも、この円はペットボトルになるかもしれないし、コップになる可能性もあります。ジャムの瓶でもいいです。このように、2nから3nへ次元がひとつ上がるとき、円はあらゆる形のものになる可能性をもっています。<br />
            &ensp;&ensp;3nの死においても次元をひとつ上げてみます。2nの円が円柱や円錐になったことと同様に、3nの死は4nにおいて、死ではない何か他のものになる可能性があるとわたしは考えています。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Img
            src={nの円_img}
            alt="nの円_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            本作のパフォーマンスで使用される予定だった舞台美術のスケッチ②
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>Dressing</h3>
            創傷被覆材とはドレッシング（Dressing）材ともいいます。「Dressing」には、「覆う」「被せる」 などの意味があり、例えば包帯や絆創膏、最も古いものでいえばハチミツのことを指します。今作では、ワークショップを行い、ビニールと洋服を素材とした創傷被覆材をつくりました。<br />
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>PUGMENTとお洋服</h3>
            &ensp;&ensp;ファッション・レーベルのPUGMENTと関わるようになったのは、彼らのファッションショー「Spring 2018」（2017年6月3日 阿佐ヶ谷Workstation）の演出をわたしがお手伝いしたことがきっかけです。<br />
            数年前のわたしは自尊心が低くく、どこへ行っても自分の存在が申し訳なく感じていました。この「存在できない/居ることができない気持ち」はPUGMENTの初のファッションショーに関わったことで変化していきました。それまで何を装うかについて気にしたこともなかったわたしは、ショーを機に考えるようになり、そして服装が変わりました。何を装うか（何に包まれるか、何を着るか）の変化に伴い、わたしに対する周囲の態度や、わたし自信の心持ちも変化したことにより、「ここに居られる（存在できる）」という経験をしました。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Img
            src={pugment_img}
            alt="pugment_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            PUGMENTの協力の元、パフォーマンスで着用された衣装を制作しました
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>おまじないのようなお洋服</h3>
            藝大を卒業したわたしは、服飾の進路を選択します。ファッションと美術を往来する作品やお洋服をつくっていく予定です。
            &ensp;&ensp;過去のわたしのように、存在できない/居ることができない誰かが、少しでもそこに居ることができるようになるための、ちょっとしたお守りやおまじないのような、そんなお洋服をつくりたいんです。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>内面化と移植</h3>
            今作では幾つかのワークショップを行いました。そのうちのひとつ、患者をつくるワークショップでは「内面化をする」という項目があります。内面化とは例えば、他者の言動や行動に影響を受けて、自分の言動や行動に変化が生じることだと考えています。<br />
            &ensp;&ensp;この関係について、わたしは「移植」を用いて考えています。レシピエント（受給者＝ワークショップ参加者）とドナー（提供者＝存在できない話しをする作者）と移植片（臓器などの移植物＝存在できない話し）の関係が「移植」であり、「内面化」の重要な点です。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Img
            src={内面化_img}
            alt="内面化_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            「患者をつくるワークショップ」で使用された図式絵画（ダイアグラム）の一部①
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>記憶転移</h3>
            記憶転移とは、臓器移植に伴いドナーの記憶の一部（ドナーの趣味や嗜好、習慣や性格、記憶など）がレシピエントに移る現象のことです。内面化のワークショップは、移植に伴う記憶転移を当てはめて考えると、明快となります。しかし、記憶転移に対して、わたしはどこか腑に落ちずにいたため、あえて記憶転移というワードを使わず、ワークショップ参加者にも説明をしませんでした。(N)<br />
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>当事者性</h3>
            当事者性と同等に「当事者ではない当事者性」が重要だとわたしは考えています。なぜなら、何かが起こっている地点と、今いる自分の距離も切実なものだからです。<br />
            &ensp;&ensp;例えば被災地や戦地などの何かが起こっている地点に対して、様々な理由から距離ができてしまうこともあります。ですが、それでもそこで起こっていることについてわたしは考えたいと思っています。そして、そこで起こってることについて考えてもいいとも思っています。(N)<br />
            <br />
            &ensp;&ensp;「記憶は心の痛みをともなって、死者とのあいだにわれわれが持ちうる唯一の絆である。」（スーザン・ソンタグ（2003）『他者の苦痛へのまなざし』みすず書房 pp.115）
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Img
            src={当事者性_img}
            alt="当事者性_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            中谷の創作ノートの一部分
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>他者</h3>
            過去の自分は他者です。住んでる場所も、着ている服も、頻繁に会う人も、話す内容も、行きたい場所も、振る舞いも……何から何まで、例えば5年前、高校に居られなかった自分と今のわたしは違うんです。(N)<br />
            <br />
            &ensp;&ensp;「他者が存在するということは、まさしく、私が外から見たり、近づいたり、ましてや入り込んだりすることが決してできない何かが存在する、【ということ】(引用注：原文では【】ではなく傍点ルビ)なのではあるまいか。言い換えれば、他我認識の不可能性においてこそ、他我の存在は成り立つのではなかろうか。他者は私の「世界に対する態度」の一部ではない。それはむしろ、そうなることを徹底的に拒むところにこそ存在するものなのだ。」（永井均（1991）『〈魂〉に対する態度』勁草書房 pp.201）(S)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>"Einstein on the Beach"</h3>
            演出 ロバート・ウィルソン、作曲 フィリップ・グラス による、1976年に初演された4幕のオペラです。<br />
            &ensp;&ensp;虚実はわかりませんが、ある話を耳にしました。精神病院に何でも知っている少年がいたそうです。ただ、私たちと少しだけ認識の差があるようで、例えば彼に、りんごを知っているかと尋ねると、すぐに答えて教えてくれるのは猫のこと。水を知っているかと尋ねると、指さすのは本。あるときアインシュタインについて尋ねると、彼ははじめて考え込み、その翌日、海辺でひとりの男性が椅子に腰掛けている絵をもってきました。彼が描いたその絵を指差して、これがアインシュタインだと言ったそうです。<br />
            &ensp;&ensp;まだわたしが十代の頃、友人たちとコミュニケーションが上手くとれないでいました。当時のわたしには「りんご」は「猫」だったし、「水」は「本」でした。しかし、それでは友人たちと居ることができないため、わたしは自分を明け渡してしまいました。だから、彼に見えているその景色を、彼自身をどうかこの世界に差し出さないでほしいと思うのです。(N)
          </P>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <Img
            src={einstein_img}
            alt="einstein_img"
            isLoadingTextBackgroundcolor={this.props.isLoadingTextBackgroundcolor}
          />
          <PCaption
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            「患者をつくるワークショップ」で使用された図式絵画（ダイアグラム）の一部②
          </PCaption>
        </DivWords>
        <DivWords
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            <h3>「被膜彫刻案」</h3>
            1992年 NTT出版『季刊インターコミュニケーション』第2号「生体被膜論」に掲載されている、アーティスト 三上晴子の誌上展覧会のイメージです。<br />
            &ensp;&ensp;「ほとんどがなにがしかのハイテクの被膜を装着し、外界から生身を守られた小さなフィギュア群は、左からそれぞれ『深海探査』『環境破壊事故』『人種問題 KKK』『軍事活動』『医学』『コンピュータ産業』『原子力発電所』『テロリスト活動』『宇宙開発』『バイオテクノロジー』」（<a href="https://www.art-it.asia/u/admin_ed_contri9_j/ug5baz0zp7bdjkwcaynm" target="_blank" >ART IT 50：追悼・三上晴子 ― 彼女はメディア・アーティストだったか（4）</a>より）<br />
            皮膜/被膜について思考し制作を行っていた三上晴子に、わたしは多大な影響を受けています。(N)
          </P>
        </DivWords>
      </section >
    )
  }
}
