// loaderもmenuも背景が透明にするやつにする、blur5pxとかで、

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Header from "./component/templates/Header.js";
import Footer from "./component/templates/Footer.js";
import Loader from "./component/loader/Loader.js";
import Content from "./component/contents/Content.js";
import ScrollToTop from "./component/loader/ScrollToTop.js";
import { BrowserRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

library.add(fab, faEnvelope);

require("./styles/main.css");

class Index extends React.Component {
  firstLoader() {
    this.setState({
      isLoadingTextBackgroundcolor: true,
    });
  }
  loader() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoadingContainerBackgroundcolor2: true });
    }, 300);
    setTimeout(() => {
      this.setState({ isLoadingContainerBackgroundcolor: true });
    }, 600);
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
    setTimeout(() => {
      this.setState({
        isLoadingTextBackgroundcolor: false,
        isLoadingContainerBackgroundcolor: false,
        isLoadingContainerBackgroundcolor2: false,
        isOverflowHidden: true
      });
    }, 1200);
    // setTimeout(() => {
    //   this.setState({ isOverflowHidden: true });
    // }, 1200);
  }
  MenuAction() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }
  ChangeOverflowHidden() {
    this.setState({ isOverflowHidden: !this.state.isOverflowHidden });
  }
  constructor() {
    super();
    this.state = {
      titleBackgroundColor: "bisque",
      isLoading: false,
      isLoadingTextBackgroundcolor: false,
      isLoadingContainerBackgroundcolor: false,
      isLoadingContainerBackgroundcolor2: false,
      isMenuOpen: false,
      isOverflowHidden: true
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoadingTextBackgroundcolor: false
      });
    }, 500);
  }
  componentWillMount() {
    this.setState({
      isLoadingTextBackgroundcolor: true
    });
  }
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="wrapper">
            <Header
              titleBackgroundColor={this.state.titleBackgroundColor}
              loader={() => this.loader()}
              firstLoader={() => this.firstLoader()}
              BurgerMenuAllowOn
              isMenuOpen={this.state.isMenuOpen}
              MenuAction={() => this.MenuAction()}
              ChangeOverflowHidden={() => this.ChangeOverflowHidden()}
            />
            <Loader
              isLoading={this.state.isLoading}
              isLoadingContainerBackgroundcolor={
                this.state.isLoadingContainerBackgroundcolor
              }
              isLoadingContainerBackgroundcolor2={
                this.state.isLoadingContainerBackgroundcolor2
              }
            />
            <Content
              isLoading={this.state.isLoading}
              loader={() => this.loader()}
              firstLoader={() => this.firstLoader()}
              isLoadingTextBackgroundcolor={
                this.state.isLoadingTextBackgroundcolor
              }
              isMenuOpen={this.state.isMenuOpen}
              isOverflowHidden={this.state.isOverflowHidden}
              ChangeOverflowHidden={() => this.ChangeOverflowHidden()}
            />
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
