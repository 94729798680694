import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const P = styled.p`
  text-align: justify;
  position: relative;
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

const PCC = styled.p`
  display: inline-block;
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
  font-size: 0.8rem;
  margin: 4px auto 0;
`;

const DivMR = styled.div`
  margin: 0 auto 6px;
  overflow: ${props =>
    props.isOverflowHidden ? "hidden" : "visible"};
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  filter: ${props =>
    props.isLoadingTextBackgroundcolor ? "blur(15px)" : "none"};
  transition: all 700ms 0s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 18px 8px 0;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
`;

const Section = styled.section`
  height: calc(100vh-156px);
  padding-bottom: 30px;
`;

const Img = styled.img`
  display: block;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: 85vw;
  }
  opacity: ${props => (props.isLoadingTextBackgroundcolor ? "0" : "1")};
  transition: all 700ms 0s ease-in-out;
`;

class MedicalRecords extends React.Component {
  ChangeOverflowHidden = () => {
    return new Promise(resolve => {
      resolve(this.props.ChangeOverflowHidden());
    });
  };
  firstLoader = () => {
    return new Promise(resolve => {
      resolve(this.props.firstLoader());
    });
  };
  mainLoader = () => {
    return new Promise(resolve => {
      resolve(this.props.loader());
    });
  };
  pushHistoryS78005 = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/MedicalRecords/S-78005"));
      }, 650);
    });
  };
  pushHistoryF25144 = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/MedicalRecords/F-25144"));
      }, 650);
    });
  };
  pushHistoryH01137 = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/MedicalRecords/H-01137"));
      }, 650);
    });
  };
  pushHistoryK86124 = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/MedicalRecords/K-86124"));
      }, 650);
    });
  };
  pushHistoryN12060 = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.history.push("/documents/MedicalRecords/N-12060"));
      }, 650);
    });
  };
  handleToS78005 = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryS78005)
      .then(this.mainLoader);
  };
  handleToF25144 = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryF25144)
      .then(this.mainLoader);
  };
  handleToH01137 = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryH01137)
      .then(this.mainLoader);
  };
  handleToK86124 = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryK86124)
      .then(this.mainLoader);
  };
  handleToN12060 = () => {
    this.ChangeOverflowHidden()
      .then(this.firstLoader)
      .then(this.pushHistoryN12060)
      .then(this.mainLoader);
  };
  render() {
    return (
      <Section className="container">
        <h2>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            "Medical Records"
          </P>
        </h2>
        <h5>
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            "field hospital"内に存在する各「患者」のカルテを掲載しています。ぜひカルテを手元で見ながら、"field hospital"周辺を巡回してみてください。
          </P>
        </h5>
        <DivMR
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
          onClick={() => { this.handleToS78005(); }}
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            S-78005<br />
            <PCC>主訴：存在する意味を与え続けられることの辛さ、花</PCC>
          </P>
        </DivMR>
        <DivMR
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
          onClick={() => { this.handleToN12060(); }}
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            N-12060<br />
            <PCC>主訴：外側（メタ）に立つ切なさ、外側から見られることの恐怖</PCC>
          </P>
        </DivMR>
        <DivMR
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
          onClick={() => { this.handleToK86124(); }}
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            K-86124<br />
            <PCC>主訴：自分は居ることができる</PCC>
          </P>
        </DivMR>
        <DivMR
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
          onClick={() => { this.handleToH01137(); }}
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            H-01137<br />
            <PCC>主訴：上京しても地元へ帰っても、エイリアン</PCC>
          </P>
        </DivMR>
        <DivMR
          isLoadingTextBackgroundcolor={
            this.props.isLoadingTextBackgroundcolor
          }
          onClick={() => { this.handleToF25144(); }}
        >
          <P
            isLoadingTextBackgroundcolor={
              this.props.isLoadingTextBackgroundcolor
            }
          >
            F-25144<br />
            <PCC>主訴：交換可能性・必要とされていないことの辛さ</PCC>
          </P>
        </DivMR>
      </Section>
    );
  }
}

export default withRouter(MedicalRecords);
