import React from 'react';
import { Redirect } from 'react-router-dom';

export default class Afterwords extends React.Component {
  render() {
    return (
      <div>Afterwords</div>
    )
  }
}
